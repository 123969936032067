import Header from '../partials/common/header/header';
import Hero from '../partials/home/hero';
import Footer from '../partials/common/footer/footer';
import About from "../partials/home/about";
import Values from "../partials/home/values";
import Packages from "../partials/home/packages";
import Destinations from "../partials/home/destinations";
import Testimonials from "../partials/home/testimonials";

function Home() {
    return (
        <>
            <Header/>
            <Hero/>
            <About/>
            <Packages/>
            <Values/>
            <Destinations/>
            <Testimonials/>
            <Footer/>
        </>
    );
}

export default Home;
