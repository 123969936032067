import {
    Container,
    Heading,
    SimpleGrid,
} from '@chakra-ui/react';
import ValueCard from './valueCard';

function Values() {
    return (
        <Container py={10} px={{ base: 18, md: 28 }} width={"100%"} maxW={"100%"} centerContent background='brand.500'>
            <Heading color={'white'}>100% Commitment Guaranteed</Heading>
            <SimpleGrid w='100%' py={10} columns={{ base: 2, md: 3 }} columnGap={10} rowGap={10}>
                <ValueCard
                    name="Personalization"
                    description="We believe every traveler is unique, and our tech-driven approach enables us to tailor tours and itineraries to match individual preferences and interests."
                />
                <ValueCard
                    name="Innovation"
                    description="Embracing emerging technologies, we constantly seek new ways to enhance travel experiences, from interactive virtual tours to smart travel planning tools."
                />
                <ValueCard
                    name="Quality"
                    description="We uphold the highest standards in service, safety, and customer satisfaction, ensuring every traveler enjoys a seamless and unforgettable journey."
                />
                <ValueCard
                    name="Sustainability"
                    description="We are committed to responsible and sustainable travel practices, minimizing our ecological footprint and supporting local communities."
                />
                <ValueCard
                    name="Connectivity"
                    description="Our tech-enabled platform connects travelers with diverse destinations worldwide, fostering cultural understanding and enriching experiences."
                />
                <ValueCard
                    name="Empowerment"
                    description="Through transparent pricing, informative content, and real-time assistance, we empower our customers to make informed travel decisions."
                />
            </SimpleGrid>
        </Container>
    );
}

export default Values;
