import { Container, Heading, SimpleGrid } from '@chakra-ui/react';
import DestinationCard from "./destinationCard";

function Destinations() {
    return (
        <Container py={20} px={{ base: 18, md: 28 }} width={"100%"} maxW={"100%"} centerContent background='brandBlack.300'>
            <Heading>Available Destinations</Heading>
            <SimpleGrid w='100%' pt={10} columns={{ base: 1, sm: 1, lg: 2 }} columnGap={10} rowGap={10}>
                <DestinationCard
                    name="Agra"
                    description="Witness the architectural marvel of the Taj Mahal, an ode to eternal love. Agra, the city of the iconic white marble monument, also boasts the majestic Agra Fort and the serene Mehtab Bagh, providing travelers with an unforgettable glimpse into India's rich history."
                    imageSource="/images/destinations/agra.png"
                />
                <DestinationCard
                    name="Kerala"
                    description="Immerse yourself in the tranquil beauty of Kerala's backwaters, a network of lagoons, lakes, and canals. Glide through the serene waters on traditional houseboats, surrounded by lush greenery, and experience the unique culture and warm hospitality of the locals."
                    imageSource="/images/destinations/munnar.png"
                />
                <DestinationCard
                    name="Varanasi"
                    description="Experience spiritual awakening in Varanasi, the spiritual capital of India. Explore the sacred Ghats along the Ganges River, witness mesmerizing Ganga Aarti ceremonies, and immerse yourself in the city's profound religious heritage."
                    imageSource="/images/destinations/varanasi.png"
                />
                <DestinationCard
                    name="Rajasthan"
                    description="Embark on a journey through the royal state of Rajasthan, where majestic forts, opulent palaces, and vibrant culture await. From the romantic city of Udaipur to the golden desert of Jaisalmer, Rajasthan offers a regal experience like no other."
                    imageSource="/images/destinations/udaipur.png"
                />
                <DestinationCard
                    name="Goa"
                    description="Embrace the laid-back vibe of Goa, India's beach paradise. Explore the sandy shores, indulge in water sports, and savor the vibrant nightlife. Goa's blend of Portuguese architecture, stunning beaches, and delectable cuisine promises an unforgettable getaway."
                    imageSource="/images/destinations/goa.png"
                />
                <DestinationCard
                    name="Rishikesh"
                    description="For the adventure seekers, Rishikesh is the ultimate destination. Known as the 'Yoga Capital of the World,' Rishikesh offers thrilling white-water rafting experiences, yoga retreats amidst the Himalayan foothills, and a peaceful escape from the hustle of city life."
                    imageSource="/images/destinations/rishikesh.png"
                />
            </SimpleGrid>
        </Container>
    );
}

export default Destinations;
