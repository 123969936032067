import {Card, CardBody, Flex, Heading, Text} from '@chakra-ui/react';

function ValueCard({ name, description }) {
    return (
        <Card minHeight='40' p='4' borderRadius='12' boxShadow={'none'}>
            <CardBody w='100%'>
                <Flex flexDirection='column' w='100%'>
                    <Heading textAlign='center' fontSize="lg">
                        {name}
                    </Heading>
                    <Text pb='.5rem' mt='2' lineHeight='1.6' textAlign='center'>
                        {description}
                    </Text>
                </Flex>
            </CardBody>
        </Card>
    );
}

export default ValueCard;
