import {Card, CardBody, Flex, Heading, Image, Text} from '@chakra-ui/react';

function DestinationCard({ imageSource, name, description }) {
    return (
        <Card minHeight='40' p='4' borderRadius='12' boxShadow={'none'}>
            <CardBody w='100%'>
                <Flex flexDirection='column' w='100%'>
                    <Image
                        src={imageSource}
                        alt={name}
                        width={'80%'}
                        margin={'auto'}
                        fallbackSrc={'/images/icons/app.png'}
                    />
                    <Heading textAlign='center' mt='6' fontSize="lg">
                        {name}
                    </Heading>
                    <Text pb='.5rem' mt='2' lineHeight='1.6' textAlign='center'>
                        {description}
                    </Text>
                </Flex>
            </CardBody>
        </Card>
    );
}

export default DestinationCard;
