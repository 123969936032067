import { Container, Heading, SimpleGrid } from '@chakra-ui/react';
import PackageCard from "./packageCard";

function Packages() {
    return (
        <Container pb={20} px={{ base: 18, md: 28 }} width={"100%"} maxW={"100%"} centerContent background='brandBlack.300'>
            <Heading>Popular Tours & Packages</Heading>
            <SimpleGrid w='100%' pt={10} columns={{ base: 1, sm: 1, lg: 1 }} columnGap={10} rowGap={10}>
                <PackageCard
                    name="Majestic Taj Odyssey"
                    description="Explore the wonders of Northern India with our Majestic Taj Odyssey. Witness the iconic Taj Mahal, delve into the vibrant streets of Delhi, and experience the cultural richness of Jaipur. This 7-day tour promises a captivating journey through India's Golden Triangle."
                    itinerary={[
                        "Day 1: Arrival in Delhi",
                        "Day 2: Delhi to Agra, Taj Mahal Visit",
                        "Day 3: Agra City Tour, Agra to Delhi",
                        "Day 4: Delhi City Tour",
                        "Day 5: Departure from Delhi"
                    ]}
                    imageSource={"https://images.unsplash.com/photo-1564507592333-c60657eea523?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1742&q=80"}
                />
                <PackageCard
                    name="Serene South Escapade"
                    description="Unwind amidst the natural beauty of South India with our Serene South Escapade. Explore the lush landscapes of Kerala, experience the backwaters of Alleppey, and indulge in the tranquil beaches of Goa. This 10-day tour offers a rejuvenating retreat for nature enthusiasts."
                    itinerary={[
                        "Day 1: Arrival in Cochin",
                        "Day 2: Cochin City Tour",
                        "Day 3: Cochin to Munnar",
                        "Day 4: Munnar Sightseeing",
                        "Day 5: Munnar to Thekkady",
                        "Day 6: Periyar Wildlife Sanctuary",
                        "Day 7: Thekkady to Alleppey, Houseboat Stay",
                        "Day 8: Alleppey to Goa, Beach Relaxation",
                        "Day 9: Goa Sightseeing",
                        "Day 10: Departure from Goa"
                    ]}
                    imageSource={"https://images.unsplash.com/photo-1616388969587-8196f32388b4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=872&q=80"}
                />
                <PackageCard
                    name="Spiritual Ganges Expedition"
                    description="Embark on a soul-stirring journey along the sacred Ganges with our Spiritual Ganges Expedition. Explore the spiritual cities of Varanasi and Rishikesh, attend mesmerizing Ganga Aarti ceremonies, and experience the divine aura of India's holiest river. This 8-day tour is a spiritual odyssey like no other."
                    itinerary={[
                        "Day 1: Arrival in Varanasi, Evening Ganga Aarti",
                        "Day 2: Varanasi City Tour",
                        "Day 3: Varanasi to Allahabad, Triveni Sangam Visit",
                        "Day 4: Allahabad to Rishikesh",
                        "Day 5: Rishikesh Yoga & Meditation Retreat",
                        "Day 6: Rishikesh to Haridwar, Evening Ganga Aarti",
                        "Day 7: Haridwar Sightseeing",
                        "Day 8: Departure from Haridwar"
                    ]}
                    imageSource={"https://images.unsplash.com/photo-1571989237340-98fb838eeef1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=928&q=80"}
                />
                <PackageCard
                    name="Royal Rajasthan Sojourn"
                    description="Embark on a regal journey through the enchanting land of Rajasthan with our Royal Rajasthan Sojourn. Explore the magnificent forts and palaces of Udaipur, Jodhpur, and Jaisalmer, witness the desert's golden beauty, and experience the grandeur of Indian royalty. This 12-day tour is a majestic adventure through the heart of Rajasthan."
                    itinerary={[
                        "Day 1: Arrival in Udaipur, City Tour",
                        "Day 2: Udaipur Palace & Lakes",
                        "Day 3: Udaipur to Jodhpur, En route Ranakpur Jain Temple",
                        "Day 4: Jodhpur Forts & Palaces",
                        "Day 5: Jodhpur to Jaisalmer, Camel Safari",
                        "Day 6: Jaisalmer Fort & Havelis",
                        "Day 7: Jaisalmer to Bikaner",
                        "Day 8: Bikaner Forts & Temples",
                        "Day 9: Bikaner to Jaipur",
                        "Day 10: Jaipur City Tour",
                        "Day 11: Jaipur to Delhi",
                        "Day 12: Departure from Delhi"
                    ]}
                    imageSource={"https://images.unsplash.com/photo-1591264247469-d072a1018915?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80"}
                />
                <PackageCard
                    name="Himalayan Adventure Trek"
                    description="Embark on an adrenaline-pumping expedition to the Himalayas with our Himalayan Adventure Trek. Trek through lush valleys, encounter picturesque mountain villages, and witness breathtaking views of the snow-capped peaks. This 7-day tour is a thrilling escapade for adventure seekers."
                    itinerary={[
                        "Day 1: Arrival in Delhi, Acclimatization",
                        "Day 2: Delhi to Rishikesh",
                        "Day 3: Rishikesh Rafting & Yoga",
                        "Day 4: Rishikesh to Joshimath",
                        "Day 5: Joshimath to Auli",
                        "Day 6: Auli Trekking & Camping",
                        "Day 7: Departure from Auli"
                    ]}
                    imageSource={"https://images.unsplash.com/photo-1669748226735-8b7946c38e54?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1371&q=80"}
                />
            </SimpleGrid>
        </Container>
    );
}

export default Packages;
