import { extendTheme } from '@chakra-ui/react';

const config = {
    initialColorMode: 'light',
    useSystemColorMode: false,
    colors: {
        brand: {
            50: "#F9EAEA",
            100: "#F3D5D5",
            500: "#C42F30",
            600: "#B02A2B",
            700: "#9C2526",
            800: "#892021",
            900: "#751C1C"
        },
        brandBlack: {
            100: "#FFFFFF",
            300: "#F7F7F7",
            500: "#EBEBEB",
            700: "#717171",
            900: "#222222"
        }
    }
};

const customTheme = extendTheme(config);

export default customTheme;
