import { Image } from '@chakra-ui/react';

function HeaderLogo() {
    return (
        <Image
            height='35px'
            width='auto'
            src='/images/logos/travelnex-logo.png'
            alt='Travelnex'
        />
    );
}

export default HeaderLogo;
