import {Center, Container, Heading, Text} from '@chakra-ui/react';

function About() {
    return (
        <Container py={20} px={{ base: 18, md: 28 }} width={"100%"} maxW={"100%"} centerContent background='brandBlack.300'>
            <Heading>About Travelnex</Heading>
            <Center mt={5} display={"flex"} flexDir={"column"}>
                <Text>At Travelnex, our vision is to revolutionize the way people explore the world by seamlessly integrating technology into travel experiences. We aim to be the leading tech-enabled tour and travel company, setting new standards for personalized and immersive journeys.</Text>
                <Text mt={2}>Our mission is to curate exceptional travel experiences that inspire and connect travelers with the beauty and diversity of the world. We are committed to leveraging innovative technologies, such as virtual tours, augmented reality, and artificial intelligence, to enhance every aspect of our travelers' journeys.</Text>
                <Text mt={2}>At the core of Travelnex is a dedication to transforming travel experiences through cutting-edge technology. We believe that technology has the power to make travel more accessible, immersive, and sustainable.</Text>
                <Text mt={2}>With Travelnex, travel goes beyond the ordinary; it becomes an immersive and transformative adventure where every moment is enriched by technology's magic. Join us on this exciting journey as we redefine travel for the digital age and create memories that last a lifetime.</Text>
            </Center>
        </Container>
    );
}

export default About;
