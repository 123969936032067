import {Center, Card, CardBody, Flex, Heading, Image, Text, Button} from '@chakra-ui/react';

function PackageCard({ imageSource, name, description, icon, itinerary }) {
    const itineraryItems = itinerary?.map((item) => {
        return <li>{item}</li>
    });

    return (
        <Card minHeight='40' p='4' borderRadius='12' boxShadow={'none'}>
            <CardBody w='100%'>
                <Flex flexDirection='row' w='100%' gap={10}>
                    {imageSource ?
                        <Image
                            src={imageSource}
                            alt={name}
                            width={'30%'}
                            margin={'auto'}
                            fallbackSrc={'/images/icons/app.png'}
                        />
                    : icon ?
                        <Center
                            fontSize={'35px'}
                        >
                            {icon}
                        </Center>
                    :
                        <></>
                    }
                    <Flex flexDirection={"column"}>
                        <Heading fontSize="lg">
                            {name}
                        </Heading>
                        <Text pb='.5rem' mt='2' lineHeight='1.6'>
                            {description}
                        </Text>
                        <ul>
                            {itineraryItems}
                        </ul>
                        <Text mt={2}>
                            <Button colorScheme={"brand"}>Book Now</Button>
                        </Text>
                    </Flex>
                </Flex>
            </CardBody>
        </Card>
    );
}

export default PackageCard;
