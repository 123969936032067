import { Container, Heading, SimpleGrid } from '@chakra-ui/react';
import TestimonialCard from './testimonialCard';

function Testimonials() {
    return (
        <Container py={20} px={{ base: 18, md: 28 }} width={"100%"} maxW={"100%"} centerContent background='brandBlack.900'>
            <Heading color={'white'}>Testimonials</Heading>
            <SimpleGrid w='100%' pt={10} columns={1} columnGap={20} rowGap={10}>
                <TestimonialCard
                    name="Priya Kapoor - Delhi, India"
                    description="My trip to Kerala with Travelnex was magical! The backwaters, the houseboat stay, and the warm locals made it truly special. I'm grateful for the well-planned itinerary and exceptional service. Can't wait to explore more with Travelnex!"
                />
                <TestimonialCard
                    name="Arjun Sharma - Mumbai, India"
                    description="The Royal Rajasthan Sojourn was like a dream come true! Palaces, forts, and the desert dunes - it felt like I was living in a fairy tale. Thanks to Travelnex for creating such a regal and memorable experience."
                />
                <TestimonialCard
                    name="Sneha Gupta - Kolkata, India"
                    description="Varanasi touched my soul! The spiritual aura, the Ganga Aarti, and the boat ride at dawn were soul-stirring. Travelnex's attention to detail and knowledgeable guides made the trip extraordinary."
                />
                <TestimonialCard
                    name="Rajesh Patel - Ahmedabad, India"
                    description="Goa with Travelnex was a blast! The beaches, water sports, and vibrant nightlife made it an unforgettable getaway. Thank you, Travelnex, for curating a fun-filled and relaxing vacation."
                />
                <TestimonialCard
                    name="Anushka Singh - Bengaluru, India"
                    description="The Himalayan Adventure Trek was a life-changing experience. The breathtaking views, the challenging trek, and the peaceful mountain villages were soul-nourishing. Travelnex's expertise and support made it an incredible journey."
                />
            </SimpleGrid>
        </Container>
    );
}

export default Testimonials;
